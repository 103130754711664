import clsx from 'clsx'
import React, { Component } from 'react'
import ReactGA from 'react-ga4'
import TagManager from 'react-gtm-module'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import { enableChat } from '../../../utility/chat'

function Reason({ imageSource, title, subText }) {
  return (
    <div className="reason">
      <img src={imageSource} />
      <h3 className="text-xl">{title}</h3>
      <p>{subText}</p>
    </div>
  )
}
//
const homePageLoadEventArguments = {
  dataLayer: {
    event: 'homeHACPageLoad',
  },
  dataLayerName: 'CYMDataLayer',
}
const tagManagerArguments = {
  gtmId: process.env.REACT_APP_GTM_ID,
  dataLayerName: 'CYMDataLayer',
}

const waysColOne = [
  {
    bold: 'LEADERSHIP',
    text: ': Identify and prioritize 3 initiatives that will make your business more money. ',
    icon: 'leadership.svg',
    alt: 'Leadership',
  },
  {
    bold: 'SALES',
    text: ': Create a Sales conversation that will help you and your team confidently close more deals without being salesy.',
    icon: 'sales.svg',
    alt: 'Sales',
  },
  {
    bold: 'OPERATIONS',
    text: ': Install a management and productivity system that will keep your operation costs low and your productivity levels high.',
    icon: 'operations.svg',
    alt: 'Operations',
  },
  // {
  //   text: 'Clarify your message so customers listen',
  //   icon: 'clarify.svg',
  //   alt: 'Light Bulb',
  // },
  // {
  //   text: 'Help you run an effective execution plan',
  //   icon: 'help-you-run.svg',
  //   alt: 'Plan in action',
  // },
]

const waysColTwo = [
  {
    bold: 'MARKETING',
    text: ': Create a marketing funnel to generate consistent business and predictable cash flow.',
    icon: 'marketing.svg',
    alt: 'Marketing',
  },
  {
    bold: 'PRODUCTS',
    text: ': Perform profitability audits and install product briefs to ensure your product offering is highly lucrative.',
    icon: 'products.svg',
    alt: 'Product',
  },
  {
    bold: 'CASH FLOW',
    text: ': Learn to use 5 checking accounts so you can manage your finances with zero confusion or stress.',
    icon: 'cashflow.svg',
    alt: 'Funnel',
  },
  // {
  //   text: 'Become a value-driven professional',
  //   icon: 'value-driven.svg',
  //   alt: 'Valuable Person',
  // },
  // {
  //   text: 'Implement a sales framework that positions the customer as the hero',
  //   icon: 'handshake.svg',
  //   alt: 'Hand shake',
  // },
]

function PlanStep({ bold, text, icon }) {
  return (
    <div className="flex flex-col space-y-2">
      <div>
        <div className="flex md:flex-row flex-col md:items-center items-start md:space-y-0 space-y-4 md:space-x-4 self-start">
          <img className="h-24" src={require(`../../../assets/ten-ways/${icon}`).default} />
          <p className="">
            <span className="light-text-gradient font-extrabold self-start text-xl tracking-wider">{bold}</span>
            {text}
          </p>
        </div>
      </div>
    </div>
  )
}

//dark bg bg-[#181C21]
const SectionContainer = ({ isDark, children }) => (
  <section
    className={clsx(
      'pb-12 flex flex-col space-y-12 justify-center items-center pt-6 min-h-screen md:px-8 px-4',
      isDark ? 'bg-[#181C21]' : 'bg-white'
    )}
    style={{
      backgroundColor: isDark ? '#181C21' : '#fff',
    }}
  >
    {children}
  </section>
)
const GridCard = ({ copy }) => (
  <div className="border border-white-card bg-white shadow-md rounded-3xl flex items-center px-6 py-12 space-x-6">
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.6">
        <path
          d="M9.2646 15.8582C9.48449 16.1793 9.95398 16.1803 10.1689 15.8551C10.1709 15.8521 10.1729 15.849 10.1749 15.846C14.2854 9.67579 17.5857 6.28166 21.0791 3.66667C22.5658 2.55522 24.0119 2 25.4154 2C27.2706 2 28.3819 2.05978 28.7494 2.17933C28.9158 2.21378 29 2.40122 29 2.74367C29 3 28.8326 3.33333 28.4988 3.74367C19.4933 14.2969 14.9995 20.5532 12.5253 23.8561C12.2083 24.2796 11.8438 24.6646 11.4268 24.9848C10.6077 25.613 9.61028 26 7.94429 26C7.93636 26 7.92943 26 7.9215 26C6.57841 25.9929 5.3611 25.1895 4.75394 23.9635C4.50433 23.458 4.21511 22.8095 3.8833 21.9747C2.82942 19.307 1 15.538 1 13.538C1 12.8034 1.50911 12.1966 2.52931 11.7173C2.91163 11.54 3.26821 11.386 3.59903 11.2553C4.75988 10.7974 6.07623 11.2107 6.79136 12.2523L9.2646 15.8571V15.8582Z"
          fill="#68E19F"
        />
      </g>
    </svg>
    <p>{copy}</p>
  </div>
)
class HomeHAC extends Component {
  constructor(props) {
    super(props)

    if (process.env.REACT_APP_GA_ID) {
      ReactGA.initialize(process.env.REACT_APP_GA_ID)
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: document.title })
    }

    TagManager.initialize(tagManagerArguments)
    TagManager.dataLayer(homePageLoadEventArguments)
    enableChat()
  }

  onSkillClick = selectedSkill => {
    const skillId = this.props.filters.allSkills.filter(skill => selectedSkill === skill.name)
    if (skillId.length > 0) {
      this.props.onFilterChange('skills', [skillId[0].id.toString()])
    }
    this.handleSearch()
  }

  handleSearch = () => this.props.router.push('/search')

  componentDidMount() {
    this.props.onFilterChange('skills', [])
    this.props.onFilterChange('budget', '')
    this.props.onFilterChange('query', '')
    this.props.onFilterChange('lat', '')
    this.props.onFilterChange('lng', '')
    this.props.onFilterChange('locationDisplayName', '')
  }

  render() {
    const isIE = /*@cc_on!@*/ false || !!document.documentMode

    const calculateSearchLink = () => {
      const { router } = this.props
      try {
        if (router.location.pathname.indexOf('podcast') > -1) {
          return '/search/coach-consult'
        }
      } catch {
        //
      }
      return '/search'
    }

    return (
      <div className="Home hac">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Business Coaching for Busy Entrepreneurs | Hire a Coach</title>
          <description>
            Get the coaching you need to grow your business, increase profits, and work less. Find a certified coach
            today!
          </description>
        </Helmet>
        <section className="hero">
          <div className="hero-wrapper">
            <div className="hero-image">
              <div className="white-polygon clip-paths" data-ie={isIE ? 'ie' : ''}>
                <div className="hero-content-triangle"></div>
              </div>
              <div className="white-triangle clip-paths">
                <div className="gold-triangle" data-ie={isIE ? 'ie' : ''}></div>
              </div>
              <div className="hero-content pw">
                <Link to="/">
                  <img
                    style={{
                      height: '80px',
                      width: '65%',
                      marginBottom: '3rem',
                    }}
                    src={require(`../../../assets/hac-new-logo.svg`).default}
                    alt="Hire a Coach"
                  />
                </Link>
                <h1 className="text-black">
                  Business Coaching for <span>Busy Entrepreneurs</span>
                </h1>
                <h5 className="tagline">
                  Work less, make more, and spend more time doing the things you truly love – in your business and in
                  your life.
                </h5>
                <Link role="button" className="largeBtn cta md:mb-6 mb-2" to={calculateSearchLink()}>
                  Find Your Coach
                </Link>
                <Link
                  role="button"
                  className="Button--underline Button--underline__white Button--underline__small cta"
                  to={{
                    pathname: '/search',
                    state: { focusName: true },
                  }}
                >
                  Search By Name
                </Link>
              </div>
            </div>
            <div className="hero-content-bottom">
              <p>
                Most business owners are living with constant stress and overwhelm. That’s not sustainable. Our business
                coaches help you work smarter and faster so your business becomes your vehicle for freedom – not a
                burden.
              </p>
            </div>
            <div className="reasons" data-ie={isIE ? 'ie' : ''}>
              <Reason
                imageSource={require('../../../assets/1-find.png').default}
                title={'1. Find your coach'}
                subText="Find the right coach for your unique needs and industry"
              />
              <Reason
                imageSource={require('../../../assets/2-hire.png').default}
                title={'2. Hire your coach'}
                subText="Get mentored through our proven frameworks"
              />
              <Reason
                imageSource={require('../../../assets/3-revenue.png').default}
                title={'3. Grow your revenue & free time'}
                subText="Enjoy a more profitable business and more time to do what you love"
              />
            </div>
          </div>
        </section>
        <SectionContainer>
          <div>
            <h2 className="md:text-7xl text-5xl font-extrabold text-center tracking-wide">
              <span className="light-text-gradient">Your Coach Will Help You</span>
              <br /> <span className="text-cbPink">Execute a 6-Step Growth Plan</span>
            </h2>
            <h3 className="text-black text-center text-xl" style={{ fontWeight: 400 }}>
              Whether you need help with all 6 of these steps or just a few, our coaches can help.
            </h3>
          </div>
          <div className="grid sm:grid-cols-2 grid-cols-1 gap-12 w-10/12">
            {waysColOne.map(col => (
              <PlanStep key={col.bold} bold={col.bold} text={col.text} icon={col.icon} />
            ))}
            {waysColTwo.map(col => (
              <PlanStep key={col.bold} bold={col.bold} text={col.text} icon={col.icon} />
            ))}
          </div>
          <Link role="button" to="/search" className="largeBtn cta">
            FIND YOUR COACH
          </Link>
        </SectionContainer>
        <section class="what-you-get">
          <div class="pw">
            <div data-st="st-seven" class="content">
              <h2 className="text-white">
                You need a <br class="desktop-only" />
                <span>business coach.</span>
              </h2>
              <p className="text-white">
                Most entrepreneurs are great in some parts of their business but need a lot of help with the rest. Our
                business coaches are certified to guide you through the essential pillars of running a business. Your
                coach will step in with proven frameworks and clear advice where you need it most, so you can focus on
                what you enjoy.
              </p>
              <a role="button" class="largeBtn cta" href="/search">
                Find Your Coach
              </a>
            </div>
            <div data-st="st-five" class="image-right">
              <img src={require(`../../../assets/mba-section-image.png`).default} alt="Teacher Teaching Class" />
            </div>
          </div>
        </section>
        <SectionContainer>
          <h2 className="md:text-7xl text-5xl font-extrabold text-center tracking-wide md:max-w-6xl md:px-0 px-4 md:mt-24">
            <span className="light-text-gradient">
              You don’t have to go through life burned out and stretched thin.
            </span>
            <span className="text-cbPink"> Our business coaches can help you…</span>
          </h2>

          <div className="grid md:grid-cols-2 grid-cols-1 gap-6">
            <GridCard copy={'Get out of the day-to-day'} />
            <GridCard copy={'Put the right processes in place'} />
            <GridCard copy={'Get dependable, predictable results'} />
            <GridCard copy={'Achieve your most ambitious goals'} />
            <GridCard copy={'Break revenue records'} />
            <GridCard copy={'Enjoy more free time and flexibility'} />
          </div>
          <Link role="button" to="/search" className="largeBtn cta">
            FIND YOUR COACH
          </Link>
        </SectionContainer>
        <SectionContainer isDark>
          <div className="flex w-full">
            <div className="md:pl-4 md:px-0 px-4 flex flex-col space-y-12 md:w-9/12 w-full">
              <h3 className="text-gradient-lavender text-3xl max-w-3xl" style={{ fontWeight: 500 }}>
                Are you interested in building a successful coaching business of your own?
              </h3>
              <h2 className="text-left text-gradient-lavender md:text-7xl text-5xl font-extrabold tracking-wide xl:max-w-5xl md:max-w-3xl max-w-full">
                If you want to be unstoppable as a coach, you need 3 things:
              </h2>
              <ol className="text-white list-decimal pl-4">
                <li className="text-white">
                  A system to find and retain <strong>high-paying clients</strong>
                </li>
                <li className="text-white">
                  A <strong>proven curriculum</strong> that delivers results to clients
                </li>
                <li className="text-white">
                  A <strong>community of coaches</strong> you can learn from
                </li>
              </ol>
              <p className="text-white font-light text-2xl max-w-3xl tracking-wider" style={{ fontWeight: 500 }}>
                Get these and much more when you join{' '}
                <a
                  class="text-cbPink font-bold underline"
                  href={'https://www.coachbuilder.com'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Coach Builder
                </a>
                .
              </p>
              <a
                role="button"
                target="_blank"
                className="largeBtn no-margin"
                href="https://www.coachbuilder.com/"
                rel="noopener noreferrer"
              >
                SCHEDULE A CALL
              </a>
            </div>
            <img
              className="w-[450px] h-auto lg:block hidden"
              src={require(`../../../assets/biz-coach-side-image.png`).default}
              alt="person"
            />
          </div>
        </SectionContainer>
      </div>
    )
  }
}
export default HomeHAC
